import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../../../../i18n/Link';
import Image from '../../../Elements/Image';
import LinkButton from '../../../Elements/LinkButton';

const Part = () => {
  const { t } = useTranslation('componentHome');
  return (
    <div className="cases">
      <div className="body-section body-section-4">
        <div className="row">
          <div className="col">
            <Link to="/managed-hosting" className="col-header" aria-label="Exonet managed hosting">
              <Image image="pagesHomeManagedHosting" />
            </Link>
            <div className="col-content">
              <h2>{t('cases.managedHosting.title')}</h2>
              <p>{t('cases.managedHosting.p1')}</p>
            </div>
            <div className="col-footer">
              <LinkButton to="/managed-hosting">
                {t('cases.managedHosting.callToAction')}
              </LinkButton>
            </div>
          </div>
          <div className="col">
            <Link to="/over-ons" className="col-header" aria-label="Meer over ons lezen">
              <Image image="pagesOverOnsGameRoom" />
            </Link>
            <div className="col-content">
              <h2>{t('cases.aboutUs.title')}</h2>
              <p>{t('cases.aboutUs.p1')}</p>
            </div>
            <div className="col-footer">
              <LinkButton to="/over-ons">{t('cases.aboutUs.callToAction')}</LinkButton>
            </div>
          </div>

          <div className="col">
            <Link to="/vacatures" className="col-header" aria-label="Bekijk onze vacatures">
              <Image
                image="footerCases"
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  bottom: 0,
                }}
                imgStyle={{
                  objectPosition: 'center 65%',
                }}
              />
            </Link>
            <div className="col-content">
              <h2>{t('cases.vacancies.title')}</h2>
              <p>{t('cases.vacancies.p1')}</p>
            </div>
            <div className="col-footer">
              <LinkButton to="/vacatures">{t('cases.vacancies.callToAction')}</LinkButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Part;
