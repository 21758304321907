import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import getYears from '../../../../../../helpers/getYears';
import LinkButton from '../../../../../Elements/LinkButton';

const Body = memo(() => {
  const { t } = useTranslation('componentHome');
  return (
    <>
      <div className="body-section body-section-1">
        <div className="row">
          <h5 className="lead">{t('intro.title')}</h5>
        </div>
      </div>
      <div className="body-section body-section-2">
        <div className="row">
          <p>{t('intro.section2.p1')}</p>
          <p>{t('intro.section2.p2', { years: getYears() })}</p>
          <p>{t('intro.section2.p3')}</p>
        </div>
      </div>
      <div className="body-section body-section-3">
        <div className="row">
          <h1>{t('intro.section3.title')}</h1>
          <div className="vision">
            <div className="title">
              <h2>
                TL
                <span>;</span>
                DR
              </h2>
            </div>
            <div className="text">
              <p>{t('intro.section3.p1')}</p>
              <p>{t('intro.section3.p2')}</p>
              <p>{t('intro.section3.p3')}</p>
              <p>{t('intro.section3.p4')}</p>
              <LinkButton to="/managed-hosting">{t('intro.buttonManagedHosting')}</LinkButton>
              <LinkButton to="/vacatures">{t('intro.buttonVacancies')}</LinkButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Body;
