import React from 'react';

import Layout, { Seo } from '../components/Layout';

import Intro from '../components/Pages/Home/Intro';
import Cases from '../components/Pages/Home/Cases';
import withTranslations from '../i18n/withTranslations';

const Index = () => (
  <Layout withParallax className="home">
    <Seo title="Follow the future" />
    <Intro />
    <Cases />
  </Layout>
);

export default withTranslations(Index);
