import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';

const CoverImage = ({ className, style, children }) => {
  const data = useStaticQuery(graphql`
    fragment desktopImage on File {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          breakpoints: [2560]
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    fragment mobileImage on File {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          breakpoints: [1000]
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    query {
      lightIntro: file(relativePath: { eq: "home/light-x.jpg" }) {
        ...desktopImage
      }
      lightIntroMobile: file(relativePath: { eq: "home/light-x-sm.jpg" }) {
        ...mobileImage
      }
      darkIntro: file(relativePath: { eq: "home/dark-x.jpg" }) {
        ...desktopImage
      }
      darkIntroMobile: file(relativePath: { eq: "home/dark-x-sm.jpg" }) {
        ...mobileImage
      }
    }
  `);

  const light = withArtDirection(getImage(data.lightIntro), [
    {
      media: '(max-width: 768px)',
      image: getImage(data.lightIntroMobile),
    },
  ]);

  const dark = withArtDirection(getImage(data.darkIntro), [
    {
      media: '(max-width: 768px)',
      image: getImage(data.darkIntroMobile),
    },
  ]);

  return (
    <div className={classNames('home-image', className)}>
      <GatsbyImage
        image={light}
        style={{ minHeight: '100vh', ...style }}
        className="img-light"
        alt=""
      />
      <GatsbyImage
        image={dark}
        style={{ minHeight: '100vh', ...style }}
        className="img-dark"
        alt=""
      />
      {children}
    </div>
  );
};

CoverImage.defaultProps = {
  style: {},
  className: null,
};

CoverImage.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({
    backgroundPosition: PropTypes.string,
    backgroundSize: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
};

export default CoverImage;
