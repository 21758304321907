import React from 'react';

import Clouds from './components/Clouds';
import Body from './components/Body';

import CoverImage from '../CoverImage';

const Part = () => (
  <div className="intro">
    <CoverImage
      className="x"
      style={{
        backgroundPosition: 'center top',
        backgroundSize: '100%',
      }}
    >
      <Clouds />
      <Body />
    </CoverImage>
  </div>
);

export default Part;
